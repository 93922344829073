/* Mixins */
.page-header .page-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-header .page-breadcrumb .save-btn {
  margin-left: 300px;
}
.tag-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fff;
  padding: 10px;
}
.tag-wrapper .tags {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tag-wrapper .tags .ant-radio-button-wrapper {
  width: 160px;
  border-radius: 10px;
}
.tag-wrapper .tags .left-dots {
  margin-left: 10px;
  color: #2393cd;
  font-size: 30px;
}
.tag-wrapper .tags .arrow-right-icon {
  color: #2393cd;
}
.tag-wrapper .tags .right-dots {
  margin-right: 10px;
  color: #2393cd;
  font-size: 30px;
}